import $ from 'blingblingjs';
import cssFocus from './components/css-focus';
import lazyLoadImages from './components/lazy';

const anchors = $('.js-jump-to');
const typography = $('.typography');
const [cookiesModal] = $('.js-cookie-modal');

// init css focus style on user tabbing
cssFocus();

// init lazy load images
lazyLoadImages();

// init jump to top anchors
if (anchors.length) {
    import('./components/jumptotarget').then((script) => {
        script.jumpToTarget(anchors);
    });
}

// init typography classes on elements
if (typography.length) {
    import('./components/typography').then((script) => {
        script.typography();
    });
}

// init cookies modal
if (cookiesModal) {
    import('./components/cookie').then((script) => {
        script.cookie(cookiesModal);
    });
}
